@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
  font-family: "SuperFunky";
  src: url("Fonts/Super\ Funky.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "DoctorGlitch";
  src: url("Fonts/Doctor\ Glitch.otf");
  font-weight: normal;
}

@font-face {
  font-family: "SegoeUi";
  src: url("Fonts/Segoe\ UI.woff");
  font-weight: normal;
}

@font-face {
  font-family: "SegoeUi";
  src: url("Fonts/Segoe\ UI\ Bold.woff");
  font-weight: bold;
}

p, a, table{
font-family: "SegoeUi";
font-weight: normal;
}

h1, h3, h6, input, table {
font-family: 'SuperFunky';
}

h2 {
font-family: 'DoctorGlitch';
}


.image-container {
  width: 330px; /* Adjust width as needed */
  height: 330px; /* Adjust height as needed */
  position: relative;
  overflow: hidden; /* Clip overflowing content */
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure image fills container */
}